<template>
    <div class="login-index">
        <div class="header-box">
            <div class="header-content">
                <img src="../../assets/images/logo_blue.png"
                     alt=""
                     class="img_logo"
                />
            </div>
        </div>
        <div id="container">
            <router-view/>
        </div>
    </div>
</template>

<script>

    export default {
        name: "login-Index",
        data() {
            return {}
        }
    }
</script>

<style scoped lang="scss">
    .login-index {
        min-width: 1100px;
        height: 100%;
        display: flex;
        flex-flow: column;
        // 头部
        .header-box {
            width: 100%;
            height: 80px;
            line-height: 80px;
            background-color: #ffffff;
            border-bottom: 1px solid #dcdcdc;

            .header-content {
                height: 80px;
                line-height: 80px;
                margin: 0 auto;
                padding: 0 20px;
                display: flex;
                flex-flow: row;
                align-items: center;

                .img_logo {
                    /*width: 200px;*/
                    height: 54px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        #container {
            flex: 1;
            overflow: auto !important;
        }
    }
</style>
